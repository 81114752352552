video  {
    border-radius: 15px;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    background-color: black;


}

video .controls{
    overflow: hidden;
    -webkit-transform: translateZ(0);  
    opacity: 100; 
}

video[controls]::-webkit-media-controls-panel {
    display: flex !important;
    opacity: 100 !important;

    overflow: hidden;
    -webkit-transform: translateZ(0);  
}
