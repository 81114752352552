video  {
    border-radius: 15px;
    overflow: hidden;
    -webkit-transform: translateZ(0);


}
.rh5v-DefaultPlayer_component {
    background-color: transparent !important;
}
.rh5v-DefaultPlayer_controls{
    border-radius: 15px;
    overflow: hidden;
    -webkit-transform: translateZ(0);  
    opacity: 100; 
}